import { getPhoneWithoutDialingCode } from '@atob-developers/shared/src/utils/formatters/PhoneFormat';
import * as Yup from 'yup';

export const inviteDriversSchema = Yup.object().shape({
  all_existing: Yup.boolean().required('Select all or select specific drivers'),
  selected_ids: Yup.array().of(Yup.number().required()).required('Select your drivers, please'),
  new_drivers: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string().min(1).required('Drivers need a first name'),
        last_name: Yup.string().min(1).required('Drivers need a last name'),
        phone: Yup.string()
          .transform((curVal) => curVal.replace(/[^\d]/g, ''))
          .min(10)
          .max(10)
          .required('Drivers need a valid phone number'),
      }),
    )
    .required('You need an array of drivers'),
});

export const inviteDriverGroupFieldValidationSchema = Yup.object().shape({
  drivers: Yup.array().of(
    Yup.object().shape({
      checked: Yup.boolean(),
      first_name: Yup.string().when('checked', {
        is: true,
        then: (schema) => schema.min(1).required('Drivers need a first name'),
        otherwise: (schema) => schema.notRequired(),
      }),
      last_name: Yup.string().when('checked', {
        is: true,
        then: (schema) => schema.min(1).required('Drivers need a last name'),
        otherwise: (schema) => schema.notRequired(),
      }),
      phone: Yup.string().when('checked', {
        is: true,
        then: (schema) =>
          schema
            .required('Phone number is required')
            .test('len', 'Phone number is not valid', (val) => {
              const number = getPhoneWithoutDialingCode(val);
              return number?.length === 10;
            }),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
  ),
});

export const createAccountValidationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Email is required'),

  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
    .required('Confirm Password is required'),
});

export const addYourDriversValidationSchema = Yup.object().shape({
  drivers: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string().required('First name is required'),
      phone: Yup.string()
        .required('Phone number is required')
        .test('len', 'Phone number is not valid', (val) => {
          const number = getPhoneWithoutDialingCode(val);
          return number?.length === 10;
        }),
    }),
  ),
});

export const addYourVehiclesValidationSchema = Yup.object().shape({
  vehicles: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
    }),
  ),
});

export const howToMaximizeDiscountsValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .required('Phone number is required')
    .test('len', 'Phone number is not valid', (val) => {
      const number = getPhoneWithoutDialingCode(val);
      return number?.length === 10;
    }),
});
