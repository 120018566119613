import { PaginatedEndpointResponse } from '@app/@types/api.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import useWindowWidth from '@app/hooks/useWindowWidth';
import AccountOverviewShared from '@app/pages/AccountOverview/AccountOverviewShared';
import ChallengeMatchCard from '@app/pages/AccountOverview/ChallengeMatchCard';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import useSWR from 'swr';
import AccountOverviewHeader from '../../pages/AccountOverview/AccountOverviewHeader';
import AddFunds from '../../pages/Wallet/WalletOverview/TransferFunds/AddFunds';
import MobileNavHomepageHeader from '../Navigation/MobileNavHomepageHeader';
import { CardTypeSelection } from '../Prepaid/SetupActivation/CardTypeSelectionModal';
import StickyButtonWrapper from '../wrappers/StickyButtonWrapper';
import PrepaidAccountOverviewCards from './PrepaidAccountOverviewCards';
import PrepaidOnboarding from './PrepaidOnboarding';
import { TopUpTransaction } from './RecentTopUps';
import { MANUAL_TOP_UP_NOTIFICATION } from './TopUpNotification';

export default function PrepaidAccountOverview() {
  const customer = useCustomer();
  const { isSmallScreen } = useWindowWidth();
  const { allowMoneyDeposit } = useThemeConfiguration();
  const [showBillingInfo] = useProduct('billing');
  const hasOnboardedToWallet = true;
  const { data: recentTopups } = useSWR<PaginatedEndpointResponse<TopUpTransaction>>(
    { url: `/treasury/transactions/recent_topups` },
    apiGetFetcher,
  );

  const [virtualCardsPilot, migratingToFlex, allSpendChallengeMatch, highSpendChallengeMatch] =
    useFeatureFlags(
      'virtual_cards_pilot',
      'ul_to_flex_promo_v2',
      'all_spend_challenge_match',
      'high_spend_challenge_match',
    );

  const addFundsButton = (
    <AddFunds
      buttonProps={{
        text: 'Add Funds',
        primary: true,
        fullWidth: true,
      }}
      customerCompanyName={customer.company_name}
      customerCompanyAddress={customer.company_address}
      onAddFunds={() => {
        window.location.href = `/?${MANUAL_TOP_UP_NOTIFICATION}=true`;
      }}
    />
  );

  const allowAddFunds = allowMoneyDeposit && hasOnboardedToWallet;

  return (
    <>
      <MobileNavHomepageHeader />
      {!migratingToFlex && virtualCardsPilot && <CardTypeSelection customer={customer} />}
      <AccountOverviewShared
        onboarding={<PrepaidOnboarding />}
        header={
          <div className="flex items-center justify-between px-4 first:pt-4 md:px-0 md:pt-0">
            <AccountOverviewHeader title={customer.company_name} />
            {!isSmallScreen && allowAddFunds && <div>{addFundsButton}</div>}
          </div>
        }
        overviewCards={
          <div className="flex w-full flex-col items-stretch gap-6 md:flex-row">
            {showBillingInfo && (
              <PrepaidAccountOverviewCards
                customer={customer}
                recentTopups={recentTopups?.data ?? []}
              />
            )}
            {(allSpendChallengeMatch || highSpendChallengeMatch) && <ChallengeMatchCard />}
          </div>
        }
      />
      {isSmallScreen && allowAddFunds && (
        <StickyButtonWrapper>{addFundsButton}</StickyButtonWrapper>
      )}
    </>
  );
}
