import PhoneInput from '@app/components/Inputs/PhoneInput';
import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { FetcherKey, postFetcher } from '@app/utils/data/fetchers';
import { addYourDriversValidationSchema } from '@app/utils/validation/onboarding-validation';
import { getPhoneWithoutDialingCode } from '@atob-developers/shared/src/utils/formatters/PhoneFormat';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import { Formik, FieldArray, FormikErrors } from 'formik';
import useSWRMutation from 'swr/mutation';

type Driver = {
  first_name: string;
  last_name: string;
  phone: string;
};

const initialDriver: Driver = {
  first_name: '',
  last_name: '',
  phone: '',
};

type CreateDriverMutationHookParams = {
  bulk_invites: {
    new_drivers: Driver[];
  };
};

type OnboardingAddYourDriversProps = {
  nextStep: () => void;
};

export default function OnboardingAddYourDrivers({ nextStep }: OnboardingAddYourDriversProps) {
  const { trigger, isMutating } = useSWRMutation<
    unknown,
    AxiosError,
    FetcherKey,
    CreateDriverMutationHookParams
  >(
    {
      url: '/drivers/driver_onboarding_create',
    },
    postFetcher,
  );

  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent();

  const handleSkip = async () => {
    await Promise.all([
      createCustomerOnboardingEvent({
        customer_onboarding_event: {
          name: CustomerOnboardingEventName.CUSTOMER_MANAGE_SPEND_ACKNOWLEDGED,
        },
      }),
      createCustomerOnboardingEvent({
        customer_onboarding_event: {
          name: CustomerOnboardingEventName.CUSTOMER_SKIP_ADD_DRIVERS,
        },
      }),
    ]);

    nextStep();
  };

  return (
    <div className="flex flex-col items-start gap-6 px-6 py-8 md:gap-10 md:p-0 md:pb-16">
      <Header
        title="Add your drivers"
        description="Track spend by drivers by assigning cards to a driver. Begin by adding the drivers in your fleet. You can connect your telematics to automatically sync your drivers and expedite this process."
      />
      <Formik
        initialValues={{ drivers: [] as Driver[] }}
        validationSchema={addYourDriversValidationSchema}
        onSubmit={async (values) => {
          const drivers = values.drivers.map((driver) => ({
            ...driver,
            phone: getPhoneWithoutDialingCode(driver.phone) ?? '',
          }));

          await Promise.all([
            trigger({ bulk_invites: { new_drivers: drivers } }),
            createCustomerOnboardingEvent({
              customer_onboarding_event: {
                name: CustomerOnboardingEventName.CUSTOMER_MANAGE_SPEND_ACKNOWLEDGED,
              },
            }),
          ]);
          nextStep();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <FieldArray name="drivers">
            {({ push }) => {
              const driverErrors = errors.drivers as FormikErrors<Driver[]> | undefined;

              return (
                <>
                  {values.drivers.map((driver, index) => (
                    <div key={index} className="flex items-start gap-4">
                      <TextField
                        placeholder="First Name"
                        name={`drivers.${index}.first_name`}
                        value={driver.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.drivers?.[index]?.first_name &&
                          !!driverErrors?.[index]?.first_name
                        }
                        helperText={
                          touched.drivers?.[index]?.first_name && driverErrors?.[index]?.first_name
                        }
                      />
                      <TextField
                        placeholder="Last Name"
                        name={`drivers.${index}.last_name`}
                        value={driver.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.drivers?.[index]?.last_name && !!driverErrors?.[index]?.last_name
                        }
                        helperText={
                          touched.drivers?.[index]?.last_name && driverErrors?.[index]?.last_name
                        }
                      />
                      <div className="w-full">
                        <PhoneInput
                          fullWidth
                          placeholder="+1 (555) 555-5555"
                          name={`drivers.${index}.phone`}
                          value={driver.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.drivers?.[index]?.phone && !!driverErrors?.[index]?.phone}
                          helperText={
                            touched.drivers?.[index]?.phone && driverErrors?.[index]?.phone
                          }
                        />
                      </div>
                    </div>
                  ))}
                  <Button
                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                    size="extra-small"
                    color="tertiary"
                    className="w-fit"
                    onClick={() => push(initialDriver)}
                  >
                    Add driver
                  </Button>
                  <FooterActionButtons
                    helperButtonText="Skip for now"
                    hideBackButton
                    handleClickNext={handleSubmit}
                    handleClickHelperText={handleSkip}
                    isDisabled={!values.drivers.length || !!errors.drivers?.length || isMutating}
                    isLoading={isMutating}
                  />
                </>
              );
            }}
          </FieldArray>
        )}
      </Formik>
    </div>
  );
}
