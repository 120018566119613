import useThemeConfiguration from '@app/app/useThemeConfiguration';
import useLocalStorage from '@app/components/popups/components/useLocalStorage';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { ReactElement, PropsWithChildren, useEffect } from 'react';
import { NewFeatureVariant, VARIANT_LABEL } from './NewFeatureBanner';

export interface ReccuringNewFeatureBannerProps {
  bannerKey: string;
  variant: NewFeatureVariant;
  onClick: () => void;
  actionText: string;
  icon?: ReactElement;
  showAgainAfterSeconds?: number;
  showLimit?: number;
}

const SHOW_LIMIT = 3; // Total number of times to show the banner
const SHOW_AGAIN_AFTER_TIME_LIMIT = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

export const ReccuringNewFeatureBanner = ({
  bannerKey,
  onClick,
  actionText,
  variant = 'new_product',
  children,
  icon,
  showAgainAfterSeconds = SHOW_AGAIN_AFTER_TIME_LIMIT,
  showLimit = SHOW_LIMIT,
}: PropsWithChildren<ReccuringNewFeatureBannerProps>): ReactElement | null => {
  const [dismissed, setDismissed] = useLocalStorage(bannerKey, false);
  const [lastShown, setLastShown] = useLocalStorage(`${bannerKey}_lastShown`, new Date().getTime());
  const [showCount, setShowCount] = useLocalStorage(`${bannerKey}_showCount`, 0);
  const { hideDismissableBanners } = useThemeConfiguration();

  // Reshows the banner if the required interval has passed since it was last shown, and the show limit has not been reached
  useEffect(() => {
    const now = new Date().getTime();

    if (!dismissed) {
      return;
    }

    if (showCount >= showLimit) {
      return;
    }

    const timeElapsedSinceLastShown = now - lastShown;

    // Check if the required interval (e.g., 3 days) has passed since it was last shown
    if (timeElapsedSinceLastShown >= showAgainAfterSeconds) {
      // Reset the dismissed state to show the banner again
      setDismissed(false);
      setShowCount(showCount + 1);
      setLastShown(now);
    }
  }, [
    dismissed,
    showCount,
    lastShown,
    setDismissed,
    setShowCount,
    setLastShown,
    showAgainAfterSeconds,
    showLimit,
  ]);

  if (dismissed || hideDismissableBanners) {
    return null;
  }

  return (
    <div className="bg-blue3 relative w-full rounded-md px-4 py-4 text-sm font-normal">
      <div className="absolute left-0 right-0 top-0 h-full overflow-hidden rounded-md">
        <div className="absolute bottom-0 right-0 top-0 -mr-20 -mt-40 hidden overflow-hidden rounded-md xl:block ">
          <img src="/images/new_product.svg" alt="" />
        </div>
        <div className="absolute bottom-0 right-0 my-auto overflow-hidden rounded-md xl:hidden ">
          <img src="/images/new_product_mobile.svg" alt="" />
        </div>
      </div>
      <div className="flex flex-row justify-start xl:items-center">
        <div className="mr-4 flex w-11 shrink-0 justify-center align-middle">
          {icon || (
            <div className="flex h-6 rounded border border-white px-2 py-1 text-[10px] uppercase leading-[15px] text-white">
              New
            </div>
          )}
        </div>
        <div className="flex flex-col justify-between gap-1 text-white xl:w-full xl:flex-row">
          <div className="flex flex-col">
            <div className="text-[10px] uppercase tracking-[.8px] text-gray-300">
              {VARIANT_LABEL[variant]}
            </div>
            <div className="text-left">{children}</div>
          </div>

          <div className="mt-2 flex flex-row gap-4 xl:mt-0">
            <Button size="small" color="primary" onClick={onClick}>
              {actionText}
            </Button>

            <Link
              component="button"
              variant="body2"
              onClick={() => {
                setDismissed(true);
                setLastShown(new Date().getTime());
                setShowCount(showCount);
              }}
              className="mr-2 bg-opacity-90 text-white decoration-white hover:bg-opacity-90 hover:text-white"
            >
              Dismiss
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
