import classNames from 'classnames';
import { ReactElement, useEffect } from 'react';

export type ModalOptionData = {
  id: string;
  heading: string | ReactElement;
  subheading: string;
  description: (string | ReactElement)[];
};

export const ModalOption = ({
  data,
  selected,
  selectOption,
}: {
  data: ModalOptionData;
  selected: boolean;
  selectOption: () => void;
}): ReactElement => {
  return (
    <>
      <button
        className={classNames(
          'mb-4 flex w-full flex-col rounded-md text-left md:mb-0 md:min-h-[250px]',
          {
            'border-2 border-black': selected,
            'border border-gray-300 pb-[1px] pt-[1px]': !selected,
          },
        )}
        onClick={selectOption}
      >
        <div className="w-full border-b border-gray-300 p-3">
          <div className="flex flex-col items-start text-gray-900">
            <h3 className="mb-1 mr-2 text-xl font-semibold">{data.heading}</h3>
            <div className="text-sm md:h-6">{data.subheading}</div>
          </div>
        </div>
        <div className="flex flex-col items-start p-2">
          <ul className="list-disc pl-5">
            {data.description.map((item, index) => (
              <li className="my-3 text-sm md:my-4" key={data.id + index}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </button>
    </>
  );
};

export const ModalOptions = ({
  options,
  selectedOptionId,
  setSelectedOptionId,
}: {
  options: ModalOptionData[];
  selectedOptionId: string | null;
  setSelectedOptionId: (_: string) => void;
}): ReactElement => {
  useEffect(() => {
    if (options.length === 1) {
      setSelectedOptionId(options[0].id);
    }
  }, [options, selectedOptionId, setSelectedOptionId]);

  return (
    <div className="">
      <div className="flex flex-col items-stretch justify-between gap-x-4 md:flex-row">
        {options.map((option) => {
          return (
            <ModalOption
              key={option.id}
              data={option}
              selected={selectedOptionId === option.id}
              selectOption={() => setSelectedOptionId(option.id)}
            />
          );
        })}
      </div>
    </div>
  );
};
