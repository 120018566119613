import { PaginationMeta } from '@app/@types/api.types';
import { DateAndTimeColumn, monospacedStyle } from '@app/components/TableV2/StandardColumns';
import TableV2, { ColumnDefinition } from '@app/components/TableV2/TableV2';
import { DEFAULT_TRANSACTIONS_PAGE_SIZE } from '@app/hooks/paging/types';
import { faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, IconButton, Pagination } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import tw from 'twin.macro';
import { TransactionStatus, WalletTransaction } from '../Overview';

const StatusPill = ({ status }: { status: TransactionStatus }) => {
  if (status === 'success') {
    return <Chip className="bg-accent-12 text-accent-11" label="Completed" />;
  }

  if (status === 'pending') {
    return <Chip className="bg-warning-2 text-warning-1" label="Pending" />;
  }

  if (status === 'failed') {
    return <Chip className="bg-error-2 text-error-1" label="Failed" />;
  }

  if (status === 'canceled') {
    return <Chip label="Canceled" color="default" />;
  }

  if (status === 'returned') {
    return <Chip label="Returned" color="default" />;
  }

  return null;
};

interface WalletTransactionsTableProps {
  transactionsPage: WalletTransaction[];
  metadata: PaginationMeta;
  onPageIndexChange: (pageIndex: number) => void;
}

export default function WalletTransactionsTable({
  transactionsPage,
  metadata,
  onPageIndexChange,
}: WalletTransactionsTableProps): ReactElement {
  const displayAccountNumber = (number: string): string => `•••• ${number.slice(-4)}`;

  const displayTransferEndpoint = (data: string | null): string => {
    if (!data) return '';

    const isNumber = !isNaN(+data);
    return isNumber && data.length > 4 ? displayAccountNumber(data) : data;
  };

  const columns = [
    {
      field: 'created_timestamp',
      valueGetter: ({ row }) => dayjs.unix(row.created_timestamp),
      ...DateAndTimeColumn('Date', 130, {
        showTimezone: false,
        dateClassName: 'font-medium',
        timeClassName: 'text-secondary',
      }),
      flex: 3,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 130,
      flex: 3,
      renderCell: ({ row }) => <StatusPill status={row.status} />,
      sortable: false,
    },
    {
      field: 'source',
      headerName: 'Source',
      minWidth: 130,
      flex: 3,
      cellClassName: 'font-medium',
      renderCell: ({ row }) => displayTransferEndpoint(row.source),
      sortable: false,
    },
    {
      field: 'destination',
      headerName: 'Destination',
      minWidth: 130,
      flex: 3,
      cellClassName: 'font-medium',
      renderCell: ({ row }) => displayTransferEndpoint(row.destination),
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Reference',
      minWidth: 130,
      flex: 3,
      cellClassName: 'font-medium',
      sortable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 130,
      flex: 2,
      renderCell: ({ row }) => (
        <div
          style={monospacedStyle}
          className={classNames(
            'flex items-center justify-between gap-9 font-medium',
            row.direction === 'inbound' && 'text-green-600',
          )}
        >
          {row.direction === 'outbound' ? '-' : '+'}
          {row.amount}
        </div>
      ),
    },
    {
      field: 'receipt_button',
      headerName: '',
      minWidth: 70,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          {row.receipt_url && (
            <a href={row.receipt_url} target="_blank" rel="noopener noreferrer">
              <IconButton>
                <FontAwesomeIcon icon={faReceipt} className="text-primary h-4 w-4" />
              </IconButton>
            </a>
          )}
        </>
      ),
      sortable: false,
    },
  ] as ColumnDefinition[];

  const rowCount = metadata?.pagination.total_count;
  const currentPage = metadata?.pagination.current_page;
  const totalPages = rowCount && Math.ceil(rowCount / DEFAULT_TRANSACTIONS_PAGE_SIZE);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, pageNumber: number) => {
    if (onPageIndexChange) {
      onPageIndexChange(pageNumber);
    }
  };

  const getPageItemsRange = () => {
    const start = (currentPage - 1) * DEFAULT_TRANSACTIONS_PAGE_SIZE + 1;
    const end = Math.min(start + DEFAULT_TRANSACTIONS_PAGE_SIZE - 1, rowCount);
    return `${start} to ${end}`;
  };

  return (
    <>
      <TableV2
        customStyles={{
          sx: {
            '.MuiDataGrid-columnSeparator': {
              ...tw`hidden`,
            },
            '.MuiDataGrid-columnHeaders': {
              ...tw`border-level-2 uppercase p-3 bg-background`,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              ...tw`font-semibold text-secondary text-xs`,
            },
            '& .MuiDataGrid-cell': {
              ...tw`border-b border-level-2 outline-none pl-5`,
            },
          },
          rowHeight: 56,
          headerHeight: 40,
          pagination: false,
        }}
        columns={columns}
        data={transactionsPage}
        loading={false}
      />
      <div className="border-level-2 flex justify-center border-t pb-3 pl-5 pr-5 pt-3 md:justify-between">
        <div className="text-secondary hidden pt-1 text-sm md:block">
          Showing{' '}
          <span className="text-primary font-medium">{`${getPageItemsRange()} of ${rowCount} results`}</span>
        </div>
        <Pagination
          className="w-full md:w-auto"
          page={currentPage || 1}
          onChange={handlePageChange}
          count={totalPages}
          shape="rounded"
          sx={{
            '.MuiButtonBase-root': {
              ...tw`font-medium text-sm text-secondary hover:text-primary hover:bg-accent-23`,
            },
            '& .Mui-selected': {
              ...tw`text-primary bg-accent-23`,
            },
            '& .MuiPagination-ul': {
              ...tw`flex justify-between`,
            },
          }}
        />
      </div>
    </>
  );
}
