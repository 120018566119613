import { countries } from '../countries';

export default function PhoneFormat({ value }: { value: string | null }): string {
  if (!value) return '';

  const phone = value.match(/.*(\d{3}).*(\d{3}).*(\d{4})$/);
  if (!phone) return '';

  return `(${phone[1]}) ${phone[2]}-${phone[3]}`;
}

export function getPhoneWithoutDialingCode(value?: string | null) {
  const dialingCode = Object.values(countries).find((country) => {
    return value?.includes(country.dialingCode);
  })?.dialingCode;

  return value?.replace(`${dialingCode} `, '').replace(/\D/g, '');
}
