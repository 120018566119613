import { EndpointResponse } from '@app/@types/api.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { apiGetFetcher, apiPostFetcher, FetcherKey } from '@app/utils/data/fetchers';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import { Slider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { ModalOptionData, ModalOptions } from '../ModalOptions/ModalOptions';
import { SecurityDepositOfferDetails } from '../SecurityDepositModals/SecurityDepositOfferDetails';
import { SecurityDeposit } from '../SecurityDepositModals/SecurityDepositOfferModal';
import { SecurityDepositPaymentPending } from '../SecurityDepositModals/SecurityDepositPaymentPending';

export const SecurityDepositNetNewModal = ({
  open,
  toggleModal,
}: {
  open: boolean;
  toggleModal: () => void;
}): ReactElement => {
  const [SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN] = useFeatureFlags(
    'security_deposit_net_new_offer_open',
  );
  const { addToast } = useToasts();
  const {
    data: existingDeposit,
    mutate: mutateExistingDeposit,
    isLoading,
  } = useSWR<EndpointResponse<SecurityDeposit>>(
    { url: '/security_deposits/net_new' },
    apiGetFetcher,
  );

  const [desiredCreditLine, setDesiredCreditLine] = useState<number>(0);
  const depositAmount = desiredCreditLine * 0.3;

  const [selectedOption, setSelectedOption] = useState<'security_deposit'>('security_deposit');
  const [securityDepositModalOpen, setSecurityDepositModalOpen] = useState<boolean>(false);

  const options: ModalOptionData[] = [
    {
      id: 'security_deposit',
      heading: <div className="text-4xl">${desiredCreditLine}</div>,
      subheading: 'Weekly credit line. Billed weekly.',
      description: [
        <div key="deposit_amount">
          Make a refundable deposit of <b>${depositAmount}</b>
        </div>,
        'Credit Line is available in 5 mins if debit card is used to make the deposit.',
        'After 3 months of on time payments, you will automatically be considered for a deposit refund.',
        SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN
          ? 'Your cards will be shipped upon accepting the offer below.'
          : '',
      ].filter((x) => x !== ''),
    },
  ];

  const { trigger: confirmSecurityDepositNetNew, isMutating: isConfirmSecurityDepositNetNew } =
    useSWRMutation<
      EndpointResponse<Record<string, string>>,
      AxiosError,
      FetcherKey,
      Record<string, string>
    >(
      {
        url: '/customer/enroll_security_deposit_net_new',
      },
      apiPostFetcher,
      {
        onError: (e) => {
          if (guardAxiosError(e)) {
            addToast(
              'Something went wrong! Please try again or contact support if the issue persists',
              { appearance: 'error' },
            );
            Sentry.captureException(e);
          }
        },
      },
    );

  const { trigger: upsertSecurityDeposit, isMutating: isUpdatingSecurityDeposit } = useSWRMutation<
    EndpointResponse<SecurityDeposit>,
    AxiosError,
    FetcherKey,
    { credit_limit_cents: number; amount_cents: number }
  >(
    {
      url: '/security_deposits/create_deposit_for_net_new',
    },
    apiPostFetcher,
    {
      onError: (e) => {
        if (guardAxiosError(e)) {
          const errorMessage = e.response?.data?.error
            ? `${e.response.data.error}.`
            : 'Something went wrong! Please try again or contact support if the issue persists';
          addToast(errorMessage, { appearance: 'error' });
          Sentry.captureException(e);
        }
      },
      onSuccess: () => {
        mutateExistingDeposit();
        setSecurityDepositModalOpen(true);
        toggleModal();
      },
    },
  );

  const handleContinue = () => {
    if (SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN) {
      confirmSecurityDepositNetNew({}).then(() => {
        upsertSecurityDeposit({
          credit_limit_cents: desiredCreditLine * 100,
          amount_cents: depositAmount * 100,
        });
      });
    } else {
      upsertSecurityDeposit({
        credit_limit_cents: desiredCreditLine * 100,
        amount_cents: depositAmount * 100,
      });
    }
  };

  if (
    existingDeposit?.data &&
    (existingDeposit.data.status === 'deposit_pending' ||
      existingDeposit.data.status === 'deposit_paid_pending_hold')
  ) {
    return (
      <Modal open={open} toggle={toggleModal}>
        <SecurityDepositPaymentPending
          securityDeposit={existingDeposit.data}
          onClose={toggleModal}
        />
      </Modal>
    );
  }

  if (isLoading) {
    return (
      <Modal open={open} toggle={toggleModal}>
        <ModalBodyContent overflowVisible={true}>
          <div className="md:mt-80">
            <SpinnerBoundary />
          </div>
        </ModalBodyContent>
      </Modal>
    );
  }

  return (
    <>
      <Modal
        open={securityDepositModalOpen}
        toggle={() => {
          setSecurityDepositModalOpen(false);
          toggleModal();
        }}
      >
        <SecurityDepositOfferDetails
          securityDeposit={existingDeposit?.data || null}
          onClose={() => {
            setSecurityDepositModalOpen(false);
            toggleModal();
          }}
        />
      </Modal>
      <Modal open={open} toggle={toggleModal}>
        <ModalHeader title="Activate your Credit Line" onClose={toggleModal} />
        <ModalBodyContent overflowVisible={true}>
          <h2 className="mb-2">
            {SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN
              ? 'Select your desired credit limit below'
              : 'Your cards have been shipped. Pay the deposit to activate your credit line.'}
          </h2>
          <Slider
            className="mb-8"
            value={desiredCreditLine}
            onChange={(_, value) => setDesiredCreditLine(value as number)}
            marks={[
              { value: 500, label: '$500' }, // add extra mark at $500
              ...Array.from({ length: 5 }, (_, index) => ({
                value: index * 1000,
                label: `$${index * 1000}`,
              })),
            ]}
            min={0}
            max={4000}
            step={null}
            valueLabelFormat={(value) => `$${value}`}
          />
          <div className="flex flex-col text-base text-gray-700">
            <ModalOptions
              options={options}
              selectedOptionId={selectedOption}
              setSelectedOptionId={setSelectedOption as Dispatch<SetStateAction<string | null>>}
            />
          </div>
        </ModalBodyContent>
        <ModalFooter>
          <LoadingButton
            loading={isUpdatingSecurityDeposit || isConfirmSecurityDepositNetNew}
            className="flex-1"
            color="primary"
            disabled={desiredCreditLine === 0}
            onClick={handleContinue}
          >
            <span>
              {SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN ? 'Accept offer and ship my cards!' : 'Continue'}
            </span>
          </LoadingButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
