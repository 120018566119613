import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import PaymentSelectionDropdown from '@app/components/PaymentModal/SelectPaymentType/PaymentSelectionDropdown';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { ErrorNotification } from '@app/components/layout';
import CustomerContext from '@app/contexts/customerContext';
import { usePaymentMethodsQuery } from '@app/hooks/query/usePaymentMethodsQuery';
import useAmountCents from '@app/hooks/use-amount-cents';
import { guardAxiosError } from '@app/utils/error/guards';
import { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import FormElement from '@atob-developers/shared/src/components/FormElement';
import SideBar, {
  SideBarBody,
  SideBarFooter,
} from '@atob-developers/shared/src/components/SideBar';
import Toggle from '@atob-developers/shared/src/components/Switch';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { convertCentsToDollars } from '@atob-developers/shared/src/utils/formatters/CurrencyFormat';
import { Button, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import classNames from 'classnames';
import { ReactElement, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { SuggestedAmounts } from '../SuggestedAmounts';
import { AutoTopUpEnablementWrapper } from './AutoTopUpEnablementWrapper';
import { ScheduleAutoTopUp } from './ScheduleAutoTopUp';
import WarningModal, { calculateWarningState } from './WarningModal';
import { renderBankAccount, renderDebitCard } from './utils/RenderPaymentMethods';

import type { AutoTopUpState } from '../Prepaid.types';

export default function AutoTopUpSidebar({
  isOpen,
  toggle,
  isLoading,
  autoTopUp,
  autoTopUpError,
  refreshAutoTopUp,
}: {
  isOpen: boolean;
  toggle: () => void;
  isLoading: boolean;
  autoTopUp: AutoTopUpState;
  autoTopUpError: unknown;
  refreshAutoTopUp: () => void;
}): ReactElement {
  if (!autoTopUp || isLoading) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return null;
  }

  return (
    <AutoTopUpSidebarContent {...{ isOpen, toggle, autoTopUp, autoTopUpError, refreshAutoTopUp }} />
  );
}

type FormErrors = {
  overall?: string;
  amount?: string;
  cadence_schedule?: string;
  startingOn?: string;
  paymentMethodId?: string;
  balance_threshold?: string;
};

function AutoTopUpSidebarContent({
  isOpen,
  toggle,
  autoTopUp,
  autoTopUpError,
  refreshAutoTopUp,
}: {
  isOpen: boolean;
  toggle: () => void;
  autoTopUp: AutoTopUpState;
  autoTopUpError: unknown;
  refreshAutoTopUp: () => void;
}): ReactElement {
  const [enabled, setEnabled] = useState(autoTopUp.id && autoTopUp.deleted_at == null);
  const initialTopUpState = autoTopUp;
  const { frequency, paymentMethodId } = initialTopUpState;
  const [topUpType, setTopUpFrequencyType] = useState<'cadence' | 'balance'>(frequency);
  const setTopUpType = (type: 'cadence' | 'balance') => {
    setTopUpFrequencyType(type);
    setHasUnsavedEdits(type !== initialTopUpState.frequency);
  };

  const minimumDepositAmount =
    useContext(CustomerContext)?.customer?.treasury?.minimum_deposit_amount?.cents || 2500;

  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<number | null>(
    paymentMethodId ?? null,
  );
  const [amountCents, setAmount] = useAmountCents(initialTopUpState.amount, (value) =>
    setFormFields({ ...formFields, amount: value }),
  );

  const [balanceCents, setBalance] = useAmountCents(initialTopUpState.balance, (value) =>
    setFormFields({ ...formFields, balance: value }),
  );

  const [disableConfirmed, setDisableConfirmed] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [warningModalError, setWarningModalError] = useState<string | null>(null);
  const [hasUnsavedEdits, setHasUnsavedEdits] = useState(false);
  const [fromDisabledToEnabled, setFromDisabledToEnabled] = useState(false);

  const attemptToCloseSidebar = () => {
    if (calculateWarningState(disableConfirmed, hasUnsavedEdits, fromDisabledToEnabled)) {
      setWarningModalOpen(true);
      return;
    }

    closeSidebar();
  };

  const closeSidebar = () => {
    setTopUpType(frequency);
    setErrors({});
    setHasUnsavedEdits(false);
    setFromDisabledToEnabled(false);
    closeWarningModal();
    toggle();
  };

  const closeWarningModal = () => {
    setDisableConfirmed(false);
    setWarningModalOpen(false);
  };

  const disableTopUp = async () => {
    setWarningModalError(null);
    if (fromDisabledToEnabled) {
      setEnabled(false);
      setFromDisabledToEnabled(false);
      return;
    }

    if (!disableConfirmed) {
      setDisableConfirmed(true);
      setWarningModalOpen(true);
      return;
    }

    try {
      await axios.delete(`/treasury/auto_topups/${autoTopUp.id}`);
      setEnabled(false);
      closeSidebar();
      addToast('Auto-Deposit deleted', { appearance: 'success' });
    } catch (error) {
      setWarningModalError(
        'We were unable to disable your Auto-Deposit. Please try again. If still unsuccessful, please contact our support team.',
      );
      refreshAutoTopUp();
    }
  };

  const { addToast } = useToasts();
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);

  const [formFields, setFormFieldValues] = useState(initialTopUpState);

  const setFormFields = (values: Partial<typeof formFields>) => {
    setFormFieldValues({ ...formFields, ...values });
    setHasUnsavedEdits(true);
  };

  const [errors, setErrors] = useState<FormErrors>({
    overall: autoTopUp.error_message || '',
    amount: '',
    cadence_schedule: '',
    startingOn: '',
    paymentMethodId: '',
    balance_threshold: '',
  });

  const { isLoading: isLoadingPaymentMethods, data: paymentMethods } = usePaymentMethodsQuery();

  if (isLoadingPaymentMethods || !paymentMethods) {
    return (
      <div>
        <div className="absolute inset-y-0 w-full">
          <SpinnerBoundary />
        </div>
      </div>
    );
  }

  const debitCards = paymentMethods?.data.filter(
    (paymentMethod) => paymentMethod?.payment_method_detail?.type === 'debit_card',
  );

  const bankAccounts = paymentMethods?.data.filter(
    (paymentMethod) => paymentMethod?.payment_method_detail?.type === 'bank_account',
  );

  const selectedPaymentMethodIdType = debitCards.some(
    (debitCard) => debitCard.id === selectedPaymentMethodId?.toString(),
  )
    ? 'debit_card'
    : bankAccounts.some((bankAccount) => bankAccount.id === selectedPaymentMethodId?.toString())
    ? 'bank_account'
    : null;

  const debitCardIsPrimary = debitCards.some((debitCard) => debitCard.is_primary);
  const paymentMethodTypeToShowInDropdown =
    selectedPaymentType ||
    selectedPaymentMethodIdType ||
    (debitCardIsPrimary ? 'debit_card' : 'bank_account');

  const showDebitCards = paymentMethodTypeToShowInDropdown === 'debit_card';

  const validate = (): boolean => {
    let errorsCopy = { ...errors, overall: '' };
    if (!selectedPaymentMethodId) {
      errorsCopy = { ...errorsCopy, paymentMethodId: 'Please select a payment method' };
    } else {
      errorsCopy = { ...errorsCopy, paymentMethodId: '' };
    }

    if (amountCents < minimumDepositAmount) {
      errorsCopy = {
        ...errorsCopy,
        amount: `Please select an amount $${
          // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
          convertCentsToDollars({
            value: minimumDepositAmount,
          })
        } or above`,
      };
    } else {
      errorsCopy = { ...errorsCopy, amount: '' };
    }

    if (topUpType === 'balance' && balanceCents < 500) {
      errorsCopy = { ...errorsCopy, balance_threshold: 'Please select a balance above $5.00' };
    } else {
      errorsCopy = { ...errorsCopy, balance_threshold: '' };
    }

    setErrors(errorsCopy);
    return Object.values(errorsCopy).some((error) => error);
  };

  const onSubmit = async () => {
    const formInvalid = validate();
    if (formInvalid) {
      return;
    }

    const payload = {
      amount_cents: amountCents,
      payment_method_id: selectedPaymentMethodId,
      frequency: topUpType,
      ...(topUpType === 'cadence'
        ? {
            cadence_period: formFields.cadence_schedule,
            start_date: formFields.startingOn,
          }
        : {}),
      ...(topUpType === 'balance' ? { balance_threshold_cents: balanceCents } : {}),
    };

    try {
      if (fromDisabledToEnabled) {
        await axios.post('/treasury/auto_topups', payload);
        addToast('Auto-Deposit enabled', { appearance: 'success' });
        closeSidebar();
      } else {
        await axios.put(`/treasury/auto_topups/${autoTopUp.id}`, payload);
        addToast('Auto-Deposit updated', { appearance: 'success' });
        closeSidebar();
      }
      setHasUnsavedEdits(false);
    } catch (e: unknown) {
      const errorMessage = `We were unable to ${
        fromDisabledToEnabled ? 'create' : 'update'
      } your Auto-Deposit. Please try again. If the problem persists, please contact support.`;
      if (!guardAxiosError(e)) {
        setErrors({ ...errors, overall: errorMessage });
        return;
      }

      const descriptiveError = e?.response?.data?.errors?.[0];

      setErrors({ ...errors, overall: descriptiveError || errorMessage });
    }
  };

  return (
    <SideBar preventClickOutside open={isOpen} toggle={attemptToCloseSidebar} title="Auto-Deposit">
      <SideBarBody>
        {autoTopUpError != null && (
          <ErrorNotification error="We are having trouble loading your Auto-Deposit settings. Please try again. If the problem persists, please contact our support team." />
        )}
        <div className="flex flex-col">
          <div className="flex items-center">
            <Toggle
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              enabled={enabled}
              setEnabled={() => {
                if (enabled) {
                  disableTopUp();
                  return;
                }
                setFromDisabledToEnabled(true);
                setEnabled(true);
              }}
            />
            <span className="ml-2 text-sm font-bold uppercase">
              {enabled ? 'Enabled' : 'Disabled'}
            </span>
          </div>
          <div className="mt-4">
            {errors.overall && <ErrorNotification error={errors.overall} />}
          </div>
          <AutoTopUpEnablementWrapper
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            enabled={enabled}
          >
            <div className="mt-4 grid grid-cols-2 gap-3">
              <button
                className={classNames(
                  'rounded border py-3 text-sm font-medium text-gray-700 sm:px-10 ',
                  {
                    'border-green-600': enabled && topUpType === 'cadence',
                  },
                )}
                onClick={() => setTopUpType('cadence')}
              >
                On a schedule
              </button>
              <button
                className={classNames(
                  'rounded border py-3 text-sm font-medium text-gray-700 sm:px-10 ',
                  {
                    'border-green-600': enabled && topUpType === 'balance',
                  },
                )}
                onClick={() => setTopUpType('balance')}
              >
                On low balance
              </button>
            </div>

            <div>
              <div className="mt-4 text-sm text-gray-700">
                Automatically deposit to your Wallet
                {topUpType === 'cadence'
                  ? ' on a recurring schedule.'
                  : ' when your balance dips below a certain threshold.'}
              </div>
              <FormElement
                element={{
                  label: `Deposit amount (min $${
                    // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
                    convertCentsToDollars({
                      value: minimumDepositAmount,
                    })
                  })`,
                  type: DataItemType.TEXT,
                  key: 'amount',
                }}
                handleOnChange={(value) =>
                  value !== null && setAmount(value.replace('$', '').trim())
                }
                value={`$${formFields.amount}`}
              />
              {errors.amount && (
                <div className="mb-2 text-xs font-medium text-red-700">{errors.amount}</div>
              )}
              <SuggestedAmounts setAmount={setAmount} />
              {topUpType === 'cadence' && (
                <ScheduleAutoTopUp
                  startDate={formFields.startingOn}
                  setStartDate={(date: Date) => setFormFields({ ...formFields, startingOn: date })}
                  cadence={formFields.cadence_schedule}
                  setCadence={(cadence_schedule: 'weekly' | 'monthly') =>
                    setFormFields({ ...formFields, cadence_schedule })
                  }
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              {topUpType === 'balance' && (
                <>
                  <FormElement
                    element={{
                      label: 'When balance is below',
                      type: DataItemType.TEXT,
                      key: 'amount',
                    }}
                    handleOnChange={(value) =>
                      value !== null && setBalance(value.replace('$', '').trim())
                    }
                    value={`$${formFields.balance}`}
                  />
                  {errors.balance_threshold && (
                    <div className="mb-2 text-xs font-medium text-red-700">
                      {errors.balance_threshold}
                    </div>
                  )}
                </>
              )}

              <label className="m mt-4 block text-lg font-bold text-gray-700">Payment method</label>
              <div className="z-50 my-4">
                <PaymentSelectionDropdown
                  selectedPaymentType={paymentMethodTypeToShowInDropdown}
                  setSelectedPaymentType={(selectedType) => {
                    setSelectedPaymentMethodId(null);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    setSelectedPaymentType(selectedType);
                  }}
                  walletAvailable={false}
                />
              </div>
              {showDebitCards && debitCards.length === 0 && (
                <div className="flex w-full flex-col justify-center">
                  <div className="mb-3 text-sm font-semibold">
                    No debit cards found.{' '}
                    <Link
                      to="/billing/payment-methods?default_show_modal=debit_card"
                      className="text-atob-green underline"
                    >
                      Add one in Payment Methods.
                    </Link>
                  </div>
                </div>
              )}
              {!showDebitCards && bankAccounts.length === 0 && (
                <>
                  No bank accounts found.{' '}
                  <Link to="/billing/payment-methods">Add one in Payment Methods.</Link>
                </>
              )}
              <InputLabel className="block text-sm font-medium text-gray-700">
                {showDebitCards ? 'Debit cards' : 'Bank accounts'}
              </InputLabel>
              <Select
                value={selectedPaymentMethodId?.toString()}
                className="my-2"
                sx={{ width: '100%' }}
                onChange={(e) => {
                  if (e.target.value) {
                    setSelectedPaymentMethodId(parseInt(e.target.value));
                  }
                }}
                renderValue={(value) => {
                  const item = (showDebitCards ? debitCards : bankAccounts).find(
                    (item) => item.id === value,
                  );
                  if (item) {
                    return (
                      <div className="text-black">
                        {showDebitCards
                          ? renderDebitCard(item as DebitCardPaymentMethodCombined)
                          : renderBankAccount(item as BankAccountPaymentMethodCombined)}
                      </div>
                    );
                  }
                }}
                disabled={false}
                displayEmpty={true}
              >
                {showDebitCards
                  ? debitCards.map((card) => (
                      <MenuItem
                        key={card.id}
                        value={card.id}
                        classes={{ root: 'flex-col items-start' }}
                      >
                        {renderDebitCard(card as DebitCardPaymentMethodCombined)}
                      </MenuItem>
                    ))
                  : bankAccounts.map((account) => (
                      <MenuItem
                        key={account.id}
                        value={account.id}
                        classes={{ root: 'flex-col items-start' }}
                      >
                        {renderBankAccount(account as BankAccountPaymentMethodCombined)}
                      </MenuItem>
                    ))}
              </Select>
              {errors.paymentMethodId && (
                <div className="text-xs font-medium text-red-700">{errors.paymentMethodId}</div>
              )}
            </div>
          </AutoTopUpEnablementWrapper>
        </div>
      </SideBarBody>
      <SideBarFooter>
        <Button onClick={attemptToCloseSidebar}>Cancel</Button>
        <Button disabled={!enabled} onClick={() => onSubmit()} color="primary">
          Save
        </Button>
      </SideBarFooter>
      <WarningModal
        isOpen={warningModalOpen}
        disableConfirm={disableConfirmed}
        hasUnsavedEdits={hasUnsavedEdits}
        fromDisabledToEnabled={fromDisabledToEnabled}
        toggle={closeWarningModal}
        onContinue={closeSidebar}
        disableTopUp={disableTopUp}
        error={warningModalError}
      />
    </SideBar>
  );
}
