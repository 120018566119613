import { SecurityDepositNetNewModal } from '@app/components/Modals/SecurityDepositModals/SecurityDepositNetNewModal';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { faPartyHorn } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement, useState } from 'react';
import { NewFeatureBanner } from './Designs/NewFeatureBanner';

export const SecurityDepositNetNewBanner = (): ReactElement | null => {
  const [SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN, SECURITY_DEPOSIT_NET_NEW_ENROLLED] = useFeatureFlags(
    'security_deposit_net_new_offer_open',
    'security_deposit_net_new_enrolled',
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  let bannerCopy = '';
  let ctaCopy = '';

  if (SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN) {
    bannerCopy =
      'You are approved for a credit line by paying a security deposit! Complete steps to activate and get your fuel cards shipped.';
    ctaCopy = 'Activate Credit Line';
  } else if (SECURITY_DEPOSIT_NET_NEW_ENROLLED) {
    bannerCopy =
      'Your fuel cards have been shipped. Pay the security deposit to activate your credit line.';
    ctaCopy = 'Pay Security Deposit';
  }

  if (!SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN && !SECURITY_DEPOSIT_NET_NEW_ENROLLED) {
    return null;
  }

  return (
    <>
      <SecurityDepositNetNewModal open={isModalOpen} toggleModal={() => setIsModalOpen(false)} />
      <NewFeatureBanner
        onClick={() => setIsModalOpen(true)}
        actionText={ctaCopy}
        bannerKey="security-deposit-net-new"
        variant="offer"
        icon={faPartyHorn}
        nonDismissable
      >
        {bannerCopy}
      </NewFeatureBanner>
    </>
  );
};
