import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import useCustomer from '@app/hooks/useCustomer';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { NewFeatureBanner } from './Designs/NewFeatureBanner';

export default function NetNewCashBackOfferBanner(): ReactElement | null {
  const isUF = useIsUberfreight(true);
  const { weekly_credit_limit, created_at } = useCustomer();
  const customerCreatedAt = dayjs(created_at);
  const today = dayjs();
  const wcl = parseFloat(weekly_credit_limit);
  if (
    isUF ||
    customerCreatedAt.isBefore(dayjs('2024-09-24T00:00:00+0000')) ||
    customerCreatedAt.add(30, 'days').diff(today, 'day') <= 0
  ) {
    return null;
  }
  return (
    <NewFeatureBanner
      bannerKey="new_cx_credit_cash_back"
      variant="limited_time_offer"
      actionText=""
    >
      Earn <strong>{FormatCurrency({ value: wcl * 0.01, options: { fromCents: false } })}</strong>{' '}
      in cash back by spending {FormatCurrency({ value: wcl, options: { fromCents: false } })} in
      your first 30 days. You have {customerCreatedAt.add(30, 'days').diff(today, 'day')} days left.
    </NewFeatureBanner>
  );
}
