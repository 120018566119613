import { TileContainer } from '@app/components/OverviewTiles/Tile';
import TilesContainer from '@app/components/OverviewTiles/TilesContainer';
import Skeleton from '@app/components/layout/Skeleton';
import useProduct from '@app/hooks/useProduct';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { ReactElement, useState } from 'react';
import AccountInfoModal from './WalletOverview/AccountInfoModal';
import { SecurityDepositDetails } from './WalletOverview/SecurityDepositDetails';
import WalletBalance from './WalletOverview/WalletBalance';
import type { AddressType } from '@app/@types/customer.types';

type WalletOverviewProps = Pick<
  StatusProps,
  'accountNumber' | 'routingNumber' | 'balance' | 'isTrusted' | 'loading'
>;

export const AccountInfoLink = ({
  isTrusted,
  accountNumber,
  routingNumber,
}: {
  isTrusted: boolean;
  accountNumber: string | null;
  routingNumber: string | null;
}): ReactElement => {
  const [showAccountInfoModal, setShowAccountInfoModal] = useState(false);

  const modalToggle = () => {
    setShowAccountInfoModal(!showAccountInfoModal);
  };

  return (
    <>
      {isTrusted && (
        <div
          onClick={modalToggle}
          className="text-secondary cursor-pointer whitespace-nowrap text-sm underline"
        >
          Account info
        </div>
      )}
      {showAccountInfoModal && (
        <AccountInfoModal
          showAccountInfoModal={showAccountInfoModal}
          modalToggle={modalToggle}
          accountNumber={accountNumber}
          routingNumber={routingNumber}
        />
      )}
    </>
  );
};

const DesktopWalletOverview = ({
  accountNumber,
  routingNumber,
  balance,
  isTrusted,
}: WalletOverviewProps) => {
  const [hasSecurityDeposit] = useProduct('security_deposits');

  return (
    <TilesContainer
      classes={{
        gridContainer: `bg-level-2 ${hasSecurityDeposit && 'grid-cols-2 grid-rows-1'}`,
      }}
      header={<div className="text-l p-4 font-semibold">Balance</div>}
    >
      <TileContainer className="border-level-2 border-t">
        <div className="mr-4 flex justify-between">
          <WalletBalance balance={balance} />
          <div className="self-end">
            <AccountInfoLink
              isTrusted={isTrusted}
              accountNumber={accountNumber}
              routingNumber={routingNumber}
            />
          </div>
        </div>
      </TileContainer>
      {hasSecurityDeposit && (
        <TileContainer className="border-level-2 border-t">
          <SecurityDepositDetails />
        </TileContainer>
      )}
    </TilesContainer>
  );
};

const MobileWalletOverview = ({
  balance,
  accountNumber,
  routingNumber,
  isTrusted,
}: WalletOverviewProps) => {
  const [hasSecurityDeposit] = useProduct('security_deposits');

  return (
    <TilesContainer
      classes={{
        gridContainer: `bg-level-2`,
      }}
      header={<div className="p-4 text-lg font-semibold">Balance</div>}
    >
      <TileContainer className="border-level-2 border-t">
        <div className="mr-4 flex justify-between">
          <WalletBalance balance={balance} />
          <div className="self-end">
            <AccountInfoLink
              isTrusted={isTrusted}
              accountNumber={accountNumber}
              routingNumber={routingNumber}
            />
          </div>
        </div>
      </TileContainer>
      {hasSecurityDeposit && (
        <TileContainer className="border-level-2">
          <SecurityDepositDetails />
        </TileContainer>
      )}
    </TilesContainer>
  );
};

export type StatusProps = {
  accountNumber: string | null;
  routingNumber: string | null;
  balance: string | null;
  onAddFunds: () => void;
  onWithdrawFunds: () => void;
  loading?: boolean;
  customerCompanyName: string;
  customerCompanyAddress: AddressType;
  isTrusted: boolean;
  status:
    | 'enrolled'
    | 'not_enrolled'
    | 'started'
    | 'pending_verification'
    | 'update_required'
    | 'suspended';
};

export default function WalletOverview(
  props: WalletOverviewProps & Pick<StatusProps, 'loading'>,
): ReactElement {
  const { loading } = props;
  const { isMobile } = useWindowWidth();
  const renderOverview = isMobile ? (
    <MobileWalletOverview {...props} />
  ) : (
    <DesktopWalletOverview {...props} />
  );

  return (
    <div>
      <div className="border-level-2 rounded-lg border bg-white shadow-sm">
        {loading ? <Skeleton /> : <div className="flex justify-between">{renderOverview}</div>}
      </div>
    </div>
  );
}
