import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import { ACCOUNT_SETUP_FEE_DESCRIPTION } from '@app/constants/unlimited';
import useCustomerOnboardingEvents, {
  CustomerOnboardingEventName,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useCustomer from '@app/hooks/useCustomer';
import { Divider } from '@mui/material';
import { useMemo, useState } from 'react';
import { AddFundsModalDataWrapper } from '../Wallet/WalletOverview/TransferFunds/AddFunds';
import IssuesDisclaimer from './IssuesDisclaimer';
import PaidFeeCard from './PaidFeeCard';
import PaySetUpFeeCard from './PaySetUpFeeCard';

export default function OnboardingActivateYourAccountPrepaid() {
  const customer = useCustomer();
  const { treasury, company_name: companyName, company_address: companyAddress } = customer;

  const [accountSetupFeeModalActive, setAccountSetupFeeModalActive] = useState(false);
  const onboardingEvents = useCustomerOnboardingEvents();
  const feesPaid = useMemo(
    () => onboardingEvents.has(CustomerOnboardingEventName.CUSTOMER_PAID_SETUP_FEE),
    [onboardingEvents],
  );

  return (
    <div className="flex flex-col items-start gap-6 px-6 py-8 md:gap-10 md:p-0 md:pb-16">
      <Header title={feesPaid ? 'Your account is now activated' : 'Activate your account'} />
      {feesPaid ? (
        <PaidFeeCard />
      ) : (
        <PaySetUpFeeCard setAccountSetupFeeModalActive={setAccountSetupFeeModalActive} />
      )}
      <AddFundsModalDataWrapper
        customerCompanyName={companyName}
        customerCompanyAddress={companyAddress}
        onAddFunds={() => (window.location.href = '/')}
        modalActive={accountSetupFeeModalActive}
        setModalActive={setAccountSetupFeeModalActive}
        minimumDepositAmountCents={treasury?.minimum_deposit_amount?.cents}
        initialAmount={treasury?.minimum_deposit_amount?.cents}
        initialDescription={ACCOUNT_SETUP_FEE_DESCRIPTION}
        scenario="account_setup_fee"
      />
      <FooterActionButtons hideBackButton isDisabled={!feesPaid} />
      <Divider flexItem />
      <IssuesDisclaimer />
    </div>
  );
}
