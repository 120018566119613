import Header from '@app/components/Navigation/Header';
import AutoTopUpSidebar from '@app/components/Prepaid/AutoTopUps/AutoTopUpSidebar';
import { useAutoTopUpData } from '@app/components/Prepaid/AutoTopUps/use-auto-topup-data';
import StickyButtonWrapper from '@app/components/wrappers/StickyButtonWrapper';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useIsAllowedToFund from '@app/utils/funding/useIsAllowedToFund';
import {
  faArrowUp,
  faEllipsisVertical,
  faPlus,
  faRefresh,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StatusProps } from './WalletOverview';
import AddFunds from './WalletOverview/TransferFunds/AddFunds';
import WithdrawFundsSidebar from './WalletOverview/TransferFunds/WithdrawFundsSidebar';
import { WithdrawFundsSidebarNew } from './WalletOverview/TransferFunds/WithdrawFundsV2/WithdrawFundsSidebarNew';

const WalletPageHeader = ({
  accountNumber,
  routingNumber,
  balance,
  onAddFunds,
  onWithdrawFunds,
  customerCompanyName,
  customerCompanyAddress,
  isTrusted,
  status,
}: Omit<StatusProps, 'cashback'>) => {
  const navigate = useNavigate();
  const canModifyFunds = status === 'enrolled' && balance !== null;
  const { autoTopUp, isLoading, autoTopUpError, refreshAutoTopUp } = useAutoTopUpData();
  const [autoTopUpSidebarOpen, setAutoTopUpSidebarOpen] = useState(false);
  const allowedToFund = useIsAllowedToFund();
  const [showNewTransferFunds] = useFeatureFlags('new_transfer_funds_ui');
  const [withdrawFundsModalActive, setWithdrawFundsModalActive] = useState(false);

  const toggleWithdrawFundsModal = () => setWithdrawFundsModalActive(!withdrawFundsModalActive);

  const closeAutoTopUpSidebar = () => {
    setAutoTopUpSidebarOpen(false);
    setTimeout(() => {
      refreshAutoTopUp();
    }, 1000);
  };

  const addFundsButton = (
    <AddFunds
      onAddFunds={onAddFunds}
      accountNumber={accountNumber}
      routingNumber={routingNumber}
      customerCompanyName={customerCompanyName}
      customerCompanyAddress={customerCompanyAddress}
      buttonProps={{
        primary: true,
        className: 'h-10 pl-4 pr-4 text-sm',
        text: 'Add funds',
        startIcon: <FontAwesomeIcon icon={faPlus} className="text-base" />,
        showInMobileHeaderMenu: false,
      }}
    />
  );

  const transferFundsButton = (
    <>
      {isTrusted && (
        <Button
          className="h-10 pl-4 pr-4 text-sm"
          fullWidth={true}
          onClick={toggleWithdrawFundsModal}
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faArrowUp} className="text-base" />}
        >
          Transfer funds
        </Button>
      )}
    </>
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = useMemo(
    () => [
      ...(isTrusted
        ? [
            {
              value: (
                <>
                  <span className="pr-px">
                    <FontAwesomeIcon icon={faUsers} />
                  </span>
                  Manage recipients
                </>
              ),
              onClick: () => navigate('/wallet/recipients'),
              disabled: () => false,
              order: 1,
            },
          ]
        : []),
      {
        value: (
          <>
            <span className="pr-1">
              <FontAwesomeIcon icon={faRefresh} />
            </span>
            Auto-deposit
          </>
        ),
        onClick: () => setAutoTopUpSidebarOpen(true),
        disabled: () => !allowedToFund,
        order: isTrusted ? 2 : 1,
      },
    ],
    [allowedToFund, isTrusted, navigate],
  );

  useExtendMobileHeaderContextMenu(...menuItems);

  return (
    <Header
      className="md:-mb-6"
      title={<p className="text-primary text-3xl font-semibold">Wallet</p>}
      mobileStickyButton={
        <StickyButtonWrapper>
          <div className="flex w-full flex-col gap-3">
            {addFundsButton}
            {transferFundsButton}
          </div>
        </StickyButtonWrapper>
      }
      rightContent={
        <div className="flex items-center justify-end gap-2">
          <div className="flex items-center justify-between p-5">
            {canModifyFunds && (
              <div className="flex gap-2">
                <div>
                  <IconButton
                    className={classNames(
                      'p-2.5 transition-none',
                      open ? 'bg-accent-23' : 'bg-transparent',
                    )}
                    size="small"
                    onClick={onMenuOpen}
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} className="h-5 w-5" />
                  </IconButton>
                  <Menu
                    classes={{ list: 'p-0' }}
                    anchorEl={anchorEl}
                    open={open}
                    onClick={onMenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'header-menu-button',
                    }}
                  >
                    {menuItems.map((item, index) => (
                      <MenuItem
                        className="text-secondary min-w-[200px] p-3 text-sm font-medium"
                        key={index}
                        onClick={item.onClick}
                        disabled={item.disabled()}
                      >
                        {item.value}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
                {transferFundsButton}
                {addFundsButton}
              </div>
            )}
            <AutoTopUpSidebar
              autoTopUp={autoTopUp}
              isLoading={isLoading}
              autoTopUpError={autoTopUpError}
              refreshAutoTopUp={refreshAutoTopUp}
              isOpen={autoTopUpSidebarOpen}
              toggle={closeAutoTopUpSidebar}
            />
            {showNewTransferFunds ? (
              <WithdrawFundsSidebarNew
                open={withdrawFundsModalActive}
                reset={toggleWithdrawFundsModal}
                onCompletedTansfer={onWithdrawFunds}
                walletBalance={balance}
              />
            ) : (
              <WithdrawFundsSidebar
                open={withdrawFundsModalActive}
                reset={toggleWithdrawFundsModal}
                onWithdrawFunds={onWithdrawFunds}
              />
            )}
          </div>
        </div>
      }
    />
  );
};

export default WalletPageHeader;
