import { EndpointResponse } from '@app/@types/api.types';
import useProduct from '@app/hooks/useProduct';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { faPartyHorn } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement, useState } from 'react';
import useSWR from 'swr';
import SecurityDepositOfferModal, {
  SecurityDeposit,
} from '../Modals/SecurityDepositModals/SecurityDepositOfferModal';
import { ULToCreditRequest } from '../Modals/ULToCreditRequest/ULToCreditRequest';
import { NewFeatureBanner } from './Designs/NewFeatureBanner';

const SecurityDepositOfferBanner = (): ReactElement | null => {
  const [offerFlow, customFlow] = useProduct('security_deposits', 'unlimited_to_credit_requests');

  const { data: securityDeposit, isLoading: loading } = useSWR<EndpointResponse<SecurityDeposit>>(
    { url: `/security_deposits/offer` },
    apiGetFetcher,
  );

  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [customModalOpen, setCustomModalOpen] = useState(false);

  if (!offerFlow && !customFlow) return null;

  if (
    offerFlow &&
    (loading ||
      !securityDeposit?.data ||
      securityDeposit.data.status == 'deposit_paid' ||
      securityDeposit.data.status == 'deposit_paid_pending_hold' ||
      securityDeposit.data.status === 'closed')
  )
    return null;

  const handleButtonClick = () => {
    if (offerFlow) {
      setOfferModalOpen(true);
    } else if (customFlow) {
      setCustomModalOpen(true);
    }
  };

  return (
    <>
      {securityDeposit && (
        <SecurityDepositOfferModal
          securityDeposit={securityDeposit.data}
          open={offerModalOpen}
          onClose={() => {
            setOfferModalOpen(false);
          }}
        />
      )}

      <ULToCreditRequest
        open={customModalOpen}
        toggleModal={() => {
          setCustomModalOpen(false);
        }}
      />

      <NewFeatureBanner
        onClick={handleButtonClick}
        actionText="Continue with Offer"
        bannerKey="security-deposit-offer-banner"
        variant="offer"
        nonDismissable={true}
        icon={faPartyHorn}
      >
        Congrats! You are qualified for a credit line by making a security deposit.
      </NewFeatureBanner>
    </>
  );
};

export default SecurityDepositOfferBanner;
