import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { DynamicBreadcrumbs } from '../Triumph/DynamicBreadcrumbs';
import MobileNavHeader from './MobileNavHeader';

type HeaderProps = {
  title: string | ReactNode;
  onBack?: (() => void) | null;
  rightContent?: ReactNode;
  searchBar?: ReactNode;
  mobileStickyButton?: ReactNode;
  className?: string;
};

export default function Header({
  title,
  rightContent,
  searchBar,
  onBack,
  mobileStickyButton,
  className,
}: HeaderProps) {
  // If a sidebar should be shown, hide the header on mobile as it is replaced by the mobile nav
  const { showAppNav } = useThemeConfiguration();
  const responsiveClasses = showAppNav ? 'hidden md:flex' : 'flex';

  return (
    <>
      <MobileNavHeader
        title={title}
        onBack={onBack}
        mobileStickyButton={mobileStickyButton}
        searchBar={searchBar}
      />
      <div
        className={classNames(
          responsiveClasses,
          'w-full flex-col items-start px-4 pt-4 md:p-0',
          className,
        )}
      >
        <DynamicBreadcrumbs />
        {!!onBack && showAppNav && (
          <Button
            color="tertiary"
            size="extra-small"
            onClick={onBack}
            startIcon={
              <FontAwesomeIcon icon={faArrowLeft} className="mr-3 h-4 cursor-pointer self-center" />
            }
          >
            Back
          </Button>
        )}
        <div className="xs:flex-row xs:items-center flex w-full flex-col flex-wrap items-start justify-between">
          <h1 className="flex h-12 items-center pb-2 text-2xl font-bold leading-[29px]">{title}</h1>
          <div className="flex items-center justify-end gap-2">
            {rightContent}
            {searchBar}
          </div>
        </div>
      </div>
    </>
  );
}
