import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { FetcherKey, apiPostFetcher } from '@app/utils/data/fetchers';
import { inviteDriversSchema } from '@app/utils/validation/onboarding-validation';
import { getPhoneWithoutDialingCode } from '@atob-developers/shared/src/utils/formatters/PhoneFormat';
import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import useSWRMutation from 'swr/mutation';
import { useInviteDriversContext } from './InviteDrivers/InviteDriversContext';
import { useOnboardingGuide } from './useOnboardingGuide';

export type Driver = {
  first_name: string;
  last_name: string;
  phone: string | null;
};

export type NewDriverField = {
  checked: boolean;
  id?: number;
} & Driver;

export const useOnboardingInviteDrivers = () => {
  const { isSelectedAll, driversCountsData, individualDrivers, data, setErrors } =
    useInviteDriversContext();

  const [isOpen, setIsOpen] = useState(false);

  const { goToNextStep } = useOnboardingGuide();

  const { trigger: createOnboardingEvent, isMutating: isSkipping } =
    useCreateCustomerOnboardingEvent();
  const { trigger: sendInvitesToDrivers, isMutating } = useSWRMutation<
    unknown,
    AxiosError,
    FetcherKey,
    {
      bulk_invites: {
        all_existing: boolean;
        selected_ids: number[];
        new_drivers: Driver[];
      };
    }
  >({ url: '/drivers/driver_onboarding_invite' }, apiPostFetcher);

  const handleAddDrivers = useCallback(
    async (drivers: NewDriverField[]) => {
      const params = {
        all_existing: isSelectedAll && !!driversCountsData?.total,
        selected_ids: Object.keys(individualDrivers),
        new_drivers: drivers
          .filter((driver) => driver.checked)
          .map((driver) => ({ ...driver, phone: getPhoneWithoutDialingCode(driver.phone) })),
      };
      try {
        const resp = await inviteDriversSchema.validate(params);
        await Promise.all([
          sendInvitesToDrivers({ bulk_invites: resp }),
          createOnboardingEvent({
            customer_onboarding_event: {
              name: CustomerOnboardingEventName.CUSTOMER_ONBOARDING_COMPLETED,
            },
          }),
        ]);
        setIsOpen(true);
      } catch (err) {
        setErrors((err as { errors: string[] }).errors.map((e: string) => e.split('.')[1]));
      }
    },
    [
      createOnboardingEvent,
      driversCountsData?.total,
      individualDrivers,
      isSelectedAll,
      sendInvitesToDrivers,
      setErrors,
    ],
  );

  const handleSkip = useCallback(async () => {
    await Promise.all([
      createOnboardingEvent({
        customer_onboarding_event: {
          name: CustomerOnboardingEventName.CUSTOMER_ONBOARDING_COMPLETED,
        },
      }),
      createOnboardingEvent({
        customer_onboarding_event: {
          name: CustomerOnboardingEventName.CUSTOMER_SKIP_INVITE_DRIVERS,
        },
      }),
    ]);
    goToNextStep();
  }, [createOnboardingEvent, goToNextStep]);

  const showLoadMoreButton = useMemo(() => {
    if (!driversCountsData) {
      return false;
    }

    const loadedDriversCount =
      data?.reduce((acc, curr) => {
        return acc + curr.data.length;
      }, 0) ?? 0;

    return loadedDriversCount < driversCountsData.total;
  }, [driversCountsData, data]);

  return {
    handleAddDrivers,
    handleSkip,
    disabled: isMutating || isSkipping,
    showLoadMoreButton,
    isOpen,
    onClose: () => setIsOpen(false),
  };
};
