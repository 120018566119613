import { UnitPrice } from '@app/@types/charge_events.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import LineItem from '@app/components/layout/LineItem';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useCustomer from '@app/hooks/useCustomer';
import { getFetcher } from '@app/utils/data/fetchers';
import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { FormatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { faChevronDown, faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
// We want the solid imports
// eslint-disable-next-line no-restricted-imports
import { faCircleCheck, faClock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  PopperProps,
} from '@mui/material';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { ReactElement, ReactNode, useMemo, useState } from 'react';
import useSWR from 'swr';

const SettledTooltip = () => (
  <>
    All spend on your card(s) qualifies you for the Weekly Discount Boost. Any Boosts you qualify
    for will apply for the <strong>rest of the current week AND all of the next week.</strong>
  </>
);

const HeaderTooltip = () => (
  <>
    <h2 className="font-semibold text-white">Save even more every week</h2>{' '}
    <p className="text-level-3">
      Qualify for additional savings each week by spending with your AtoB Card
    </p>
  </>
);

const CurrentSpendTooltip = ({ centsBack, date }: { centsBack: number; date: Dayjs }) => (
  <>
    You're currently earning an <strong>extra {centsBack}¢ back</strong> on every gallon you
    purchase <strong>through {date.format('MM/DD')}</strong>. Your earnings will be reflected as a
    credit on your next billing statement.
  </>
);

type ChallengeMatchLevel = { level: number; cents_back: number; wcl_utilized_percent: number };

export default function ChallengeMatchCard(): ReactElement {
  const { weekly_credit_limit } = useCustomer();
  const wclAsNumber = parseFloat(weekly_credit_limit) * 100;
  const { data: challengeMatch, isLoading } = useSWR<{
    last_week_achieved_level: ChallengeMatchLevel | null;
    this_week_achieved_level: ChallengeMatchLevel | null;
    pending_amt: UnitPrice;
    settled_amt: UnitPrice;
    levels: ChallengeMatchLevel[];
  }>({ url: '/challenge_match' }, getFetcher);

  const [showModal, setShowModal] = useState(false);

  const partner = useChannelPartner();

  const nextLevel = useMemo(() => {
    return (
      challengeMatch?.levels.find((level) => {
        const amountNeededRaw = wclAsNumber * (level.wcl_utilized_percent / 100);
        const amountNeeded = Math.ceil((amountNeededRaw / 10) * 10);
        return (challengeMatch.settled_amt.cents ?? 0) < amountNeeded;
      }) ?? null
    );
  }, [challengeMatch, wclAsNumber]);

  const max = nextLevel
    ? wclAsNumber * (nextLevel.wcl_utilized_percent / 100)
    : wclAsNumber *
      ((challengeMatch?.levels[challengeMatch?.levels.length - 1].wcl_utilized_percent ?? 0) / 100);

  // I hate myself
  let currentLevel = null;
  let throughDate = dayjs();
  if (
    challengeMatch?.last_week_achieved_level == null &&
    challengeMatch?.this_week_achieved_level == null
  ) {
    currentLevel = null;
  } else if (
    challengeMatch?.last_week_achieved_level != null &&
    challengeMatch.this_week_achieved_level == null
  ) {
    currentLevel = challengeMatch?.last_week_achieved_level;
    throughDate = dayjs().endOf('week');
  } else if (
    challengeMatch?.last_week_achieved_level == null &&
    challengeMatch?.this_week_achieved_level != null
  ) {
    currentLevel = challengeMatch?.this_week_achieved_level;
    throughDate = dayjs().endOf('week').add(7, 'days');
  } else if (
    challengeMatch?.last_week_achieved_level?.level ??
    0 > (challengeMatch?.this_week_achieved_level?.level ?? 0)
  ) {
    currentLevel = challengeMatch.last_week_achieved_level;
    throughDate = dayjs().endOf('week');
  } else {
    currentLevel = challengeMatch.this_week_achieved_level;
    throughDate = dayjs().endOf('week').add(7, 'days');
  }

  return (
    <RoundedCard className="relative flex-shrink-0 basis-80 overflow-visible">
      <div className="flex items-center justify-between border-b border-gray-100 px-4 py-[10px] text-sm font-semibold md:px-6">
        Weekly Discount Boost
        <MobileFriendlyTooltip title={<HeaderTooltip />}>
          <FontAwesomeIcon icon={faCircleInfo} />
        </MobileFriendlyTooltip>
      </div>
      <div className="box-border px-6">
        {isLoading ? (
          <SpinnerBoundary />
        ) : (
          <>
            <SpendTracker
              spent={nextLevel ? challengeMatch?.settled_amt.cents ?? 0 : max}
              pending={nextLevel ? challengeMatch?.pending_amt.cents ?? 0 : 0}
              max={max}
            >
              <div className="flex flex-1 flex-col items-center justify-center self-center">
                <p className="text-secondary text-xs font-normal">Current weekly spend</p>
                <span className="text-primary flex items-center gap-1.5 text-2xl font-semibold">
                  {formatCurrencyToNearestDollar(challengeMatch?.settled_amt.cents ?? 0)}{' '}
                  <MobileFriendlyTooltip title={<SettledTooltip />}>
                    <FontAwesomeIcon icon={faCircleInfo} className="text-secondary h-4 w-4" />
                  </MobileFriendlyTooltip>
                </span>
                {nextLevel ? (
                  <Chip
                    label={`${formatCurrencyToNearestDollar(
                      max - (challengeMatch?.settled_amt.cents ?? 0),
                    )} to next boost`}
                    size="small"
                    color="grey"
                  />
                ) : (
                  <Chip label="Completed" size="small" color="green" />
                )}
              </div>
            </SpendTracker>
            <div className="flex flex-row items-center justify-center gap-2 pt-3">
              {challengeMatch?.levels.map((level) => (
                <LevelChip
                  key={level.level}
                  settledSpend={challengeMatch.settled_amt.cents ?? 0}
                  level={level}
                  wcl={wclAsNumber}
                />
              ))}
            </div>
            <div className="bg-background border-level-2 mt-2 flex items-center justify-between self-stretch rounded border px-[10px] py-[6px]">
              <span className="text-secondary flex items-center gap-[6px] text-sm">
                Current boost{' '}
                {currentLevel && (
                  <MobileFriendlyTooltip
                    title={
                      <CurrentSpendTooltip centsBack={currentLevel.cents_back} date={throughDate} />
                    }
                  >
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </MobileFriendlyTooltip>
                )}
              </span>
              {currentLevel ? (
                <span className="text-primary text-base font-medium">
                  {currentLevel?.cents_back}¢ per gal
                </span>
              ) : (
                <span className="text-primary text-base font-medium">0¢ per gal</span>
              )}
            </div>
            <Button
              color="secondary"
              fullWidth
              size="small"
              className="my-2"
              onClick={() => setShowModal(true)}
            >
              View Details
            </Button>
          </>
        )}
      </div>
      <Modal open={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader title="Weekly Discount Boost" onClose={() => setShowModal(!showModal)} />
        <ModalBodyContent>
          <div className="text-secondary text-base">
            Qualify for additional savings each week by spending with your{' '}
            {partner.partnerName ?? 'AtoB'} card.
          </div>
          <div className="border-level-2 bg-background my-6 flex flex-col gap-3 rounded border px-5 py-4">
            <LineItem
              label={
                <>
                  Current boost {throughDate ? <>(through {throughDate.format('MM/DD')})</> : null}
                </>
              }
              value={
                currentLevel ? (
                  <span className="text-primary text-base font-medium">
                    {currentLevel?.cents_back}¢ per gal
                  </span>
                ) : (
                  <span className="text-secondary text-base">0¢ per gal</span>
                )
              }
            />
            <LineItem
              label="Current week spend"
              value={
                formatCurrencyToNearestDollar(challengeMatch?.settled_amt.cents ?? 0) +
                ' / ' +
                formatCurrencyToNearestDollar(max)
              }
            />
          </div>
          <Accordion>
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
              <span className="text-primary text-base font-medium">
                How is my Discount Boost credit calculated?
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="text-secondary flex flex-col gap-4 text-sm">
                <p>
                  Boosts earned will apply to every subsequent transaction for the current week AND
                  all transactions for the following week.
                </p>
                <p>
                  For example, if you qualify for the first Boost after a transaction on Wednesday
                  morning, every subsequent transaction for the rest of the week AND all
                  transactions next week will earn a 1¢pg Discount Boost. If you then qualify for
                  the second Boost on Thursday, your Discount Boost will update to 2¢pg for the rest
                  of the week AND next week.
                </p>
                <p>
                  Your Weekly Discount Boost credit will be calculated each Wednesday for the
                  proceeding week's transactions, and you will see the credit applied on your next
                  statement thereafter. The week is calculated as Monday-Sunday Pacific time.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
        </ModalBodyContent>
      </Modal>
    </RoundedCard>
  );
}

function LevelChip({
  settledSpend,
  wcl,
  level,
}: {
  settledSpend: number;
  level: ChallengeMatchLevel;
  wcl: number;
}): ReactElement {
  // const amountNeeded = Math.ceil(((wcl * (level.wcl_utilized_percent / 100)) / 10) * 10) * 100;
  const amountNeededRaw = wcl * (level.wcl_utilized_percent / 100);
  const amountNeeded = Math.ceil((amountNeededRaw / 10) * 10);
  const hasReached = settledSpend >= amountNeeded;
  return (
    <MobileFriendlyTooltip title={`Activated at ${formatCurrencyToNearestDollar(amountNeeded)}`}>
      <div className="border-level-2 flex items-center gap-0.5 rounded-[30px] border py-px pl-0.5 pr-2">
        {hasReached ? (
          <FontAwesomeIcon icon={faCircleCheck} className="text-accent-11 h-5 w-5" />
        ) : (
          <FontAwesomeIcon icon={faClock} className="text-tertiary h-5 w-5" />
        )}
        <span className={classNames('text-sm', hasReached ? 'text-primary' : 'text-secondary')}>
          {level.cents_back}¢
        </span>
      </div>
    </MobileFriendlyTooltip>
  );
}

function roundToPlaces(num: number, places: number) {
  return Math.round(num / places) * places;
}

function formatCurrencyToNearestDollar(cents: number) {
  return FormatCurrency({
    value: roundToPlaces(cents, 100),
  }).replace('.00', '');
}

function rectGenerator(x: number, y: number) {
  return {
    getBoundingClientRect: () => ({
      x,
      y,
      width: 10,
      height: 10,
      bottom: y + 10,
      left: x,
      right: x + 10,
      top: y,
      toJSON: () => ({}),
    }),
  };
}

const SpendTracker = ({
  spent,
  pending,
  max,
  children,
}: {
  spent: number;
  pending: number;
  max: number;
  children: ReactNode;
}) => {
  const [popoverContent, setPopoverContent] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl']>(null);

  const spentPercentage = (spent / max) * 100;
  const pendingPercentage = Math.min((pending / max) * 100, 100 - spentPercentage);

  const radius = 96;
  const strokeWidth = 16;
  const center = 104;

  // Using 270 degrees of the circle
  const maxAngle = 270;
  const startAngle = 225; // Start from bottom-left (225 degrees)

  const spentAngle = (spentPercentage / 100) * maxAngle;
  const pendingAngle = (pendingPercentage / 100) * maxAngle;

  const describeArc = (startAngle: number, endAngle: number) => {
    const start = polarToCartesian(center, center, radius, endAngle);
    const end = polarToCartesian(center, center, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');
  };

  const polarToCartesian = (
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number,
  ) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const id = popoverContent ? 'virtual-element-popper' : undefined;

  return (
    <div className="flex w-full flex-col items-center justify-center pt-4">
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <svg className="w-52" viewBox="0 0 208 185">
          {/* Background Arc */}
          <path
            d={describeArc(startAngle, startAngle + maxAngle)}
            fill="none"
            stroke="#F0F1F4"
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
          />
          {/* Pending Arc */}
          <path
            d={describeArc(startAngle + spentAngle, startAngle + spentAngle + pendingAngle)}
            className="stroke-[#D9E8DF]"
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
            onTouchStart={(e) => {
              setAnchorEl(rectGenerator(e.touches[0].clientX, e.touches[0].clientY));
              setPopoverContent(`Pending: ${formatCurrencyToNearestDollar(pending)}`);
              setOpen(true);
            }}
            onMouseEnter={(e) => {
              setAnchorEl(rectGenerator(e.clientX, e.clientY));
              setPopoverContent(`Pending: ${formatCurrencyToNearestDollar(pending)}`);
              setOpen(true);
            }}
            onMouseMove={(e) => {
              setAnchorEl(rectGenerator(e.clientX, e.clientY));
              setPopoverContent(`Pending: ${formatCurrencyToNearestDollar(pending)}`);
              setOpen(true);
            }}
            onMouseLeave={() => {
              setAnchorEl(null);
              setOpen(false);
            }}
          />
          {/* Spent Arc */}
          <path
            d={describeArc(startAngle, startAngle + spentAngle)}
            fill="none"
            stroke="#3E9E68"
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
            onTouchStart={(e) => {
              setAnchorEl(rectGenerator(e.touches[0].clientX, e.touches[0].clientY));
              setPopoverContent(`Spent: ${formatCurrencyToNearestDollar(spent)}`);
              setOpen(true);
            }}
            onMouseEnter={(e) => {
              setAnchorEl(rectGenerator(e.clientX, e.clientY));
              setPopoverContent(`Spent: ${formatCurrencyToNearestDollar(spent)}`);
              setOpen(true);
            }}
            onMouseMove={(e) => {
              setAnchorEl(rectGenerator(e.clientX, e.clientY));
              setPopoverContent(`Spent: ${formatCurrencyToNearestDollar(spent)}`);
              setOpen(true);
            }}
            onMouseLeave={() => {
              setOpen(false);
              setAnchorEl(null);
            }}
          />
          <foreignObject x="30" y="60" width="150" height="90">
            {children}
          </foreignObject>
          <text
            x={45}
            y={175}
            dominantBaseline="middle"
            textAnchor="start"
            className="text-secondary fill-secondary font-sans text-xs font-semibold"
          >
            $0
          </text>
          {/* Text at the end of the full arc */}
          <text
            x={165}
            y={175}
            dominantBaseline="middle"
            textAnchor="end"
            className="text-secondary fill-secondary font-sans text-xs font-semibold"
          >
            {formatCurrencyToNearestDollar(max)}
          </text>
        </svg>
      </ClickAwayListener>
      <Popper id={id} open={open} anchorEl={anchorEl} transition placement="bottom-start">
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              classes={{
                root: 'bg-blue4 shadow-tooltip rounded text-sm font-medium text-white p-2.5',
              }}
            >
              {popoverContent}
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};
